<template>
  <v-layout class="d-flex flex-column">
    <v-card-title class="ma-0 pa-0 mb-4">
      <v-btn @click.stop="$router.go(-1)" icon>
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleView = !toggleView"
        ><v-icon>{{
          toggleView ? "mdi-view-list" : "mdi-view-grid"
        }}</v-icon></v-btn
      >
      <!-- <v-icon large color="orange">mdi-play-box</v-icon> -->
      <div class="title ml-2">
        {{ $lang("VIDEOS") }}
      </div>
    </v-card-title>
    <v-divider v-if="authUser && !['student'].includes(authUser.role)" />
    <!-- <ReelsDialog  v-if="authUser && !['student'].includes(authUser.role)" /> -->
    <v-divider></v-divider>
    <v-row dense class="mt-4 d-flex flex-row justify-center">
      <v-col
        cols="12"
        :md="toggleView ? 3 : 5"
        :sm="toggleView ? 4 : 5"
        v-for="(video, idx) in items"
        :key="idx"
      >
        <video-card
          v-if="toggleView"
          :video="video"
          @clicked="(vdo) => handleClick(vdo)"
        />
        <video-card-vertical
          v-else
          :video="video"
          @clicked="(vdo) => handleClick(vdo)"
        />
      </v-col>
    </v-row>
    <v-row v-if="items">
      <v-col cols="12">
        <query-page-redirect :pagination="pagination" />
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import QueryPageRedirect from "@/components/QueryPageRedirect";
// import { Device } from "@capacitor/device";
// import { interstitial } from "@/plugins/admob/interstitial";
import VideoCard from "../modules/VideoCard";
import VideoCardVertical from "../modules/VideoCardVertical";
// import ReelsDialog from "../modules/ReelsDialog";
export default {
  name: "videos",
  components: { QueryPageRedirect, VideoCard, VideoCardVertical,
  //  ReelsDialog
    },
  data() {
    return {
      toggleView: true,
      loading: true,
      items: [],
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
      info: null,
      isAdShown: false,
      videos: [
        {
          ytId: "_RQXILG9sVE",
        },
        {
          ytId: "P1NvtjZFvuU",
        },
        {
          ytId: "RX2R-W3WPA4",
        },
        {
          ytId: "eFUsvRytg5A",
        },
        {
          ytId: "-ItEPkCuu3M",
        },
        { ytId: "K29AS2wtFAI" },
        { ytId: "LNbUxrHB3Uk" },
        { ytId: "0c_oXgBgwhE" },

        {
          ytId: "FOmpQ1cBfDs",
        },
        {
          ytId: "tdu4gOeAtw0",
        },
        {
          ytId: "pVqK0cxCVOs",
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    handleClick(video) {
      this.$router.push({
        name: "video",
        params: { id: video.id },
        query: {
          parent_page: this.$route.query.page,
          ytid: video.ytid,
          slug: video.slug,
        },
      });
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    fetchItems(page = 1) {
      this.loading = true;
      bus.$emit("showWait");

      axios
        .get("videos/public?orderby=featured|desc&page=" + page)
        .then((res) => {
          this.items = res.data.data;
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;

          bus.$emit("hideWait");
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        });
    },
  },
  async created() {
    this.fetchItems(this.$route.query.page);
    // this.info = await Device.getInfo();
    // if (!this.isAdShown) {
    //   if (this.info && this.info.platform != "web") {
    //     await interstitial();
    //     this.isAdShown = true;
    //   }
    // }
  },
};
</script>
